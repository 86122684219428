import React, { useMemo } from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import useStaticImage from '@/hooks/useStaticImage';

const StaticImg = ({ path = null, alt = null, onData = null, ...props }) => {
  const getStaticImages = useStaticImage();

  const image = useMemo(() => getImage(
    getStaticImages(path)
  ), [getStaticImages, path]);

  if (!path) return null;

  const desc = !alt ? { role: "presentation", alt: '' } : { alt };

  onData && onData(image);

  return <GatsbyImage
    image={image}
    layout="fixed"
    {...desc}
    {...props}
  />;
};

export default StaticImg;
