import React from 'react';
import { Link } from 'gatsby';

import StaticImg from '@/components/common/StaticImg.jsx';

const Card = ({ date, title, image, content, link }) => {
  return (
    <div className='rounded-2xl bg-white lg:p-4 group/card overflow-hidden md:overflow-visible'>
      <div className="md:flex md:group-odd/card:flex-row-reverse">
        <div className="md:w-1/2">
          <div className="md:rounded-lg overflow-hidden h-full">
            <StaticImg path={image} className="w-full h-full" />
          </div>
        </div>

        <div className="md:w-1/2">
          <div className="py-8 px-4 sm:p-8 lg:p-4 sm:pt-10 lg:pr-14 group-even/card:md:pr-8 group-even/card:lg:pr-4 group-even/card:md:pl-10 group-even/card:lg:pl-14">
            <div className='mb-4 text-black/40 font-bold'>{date}</div>

            <h2 className="mb-4 text-[22px] sm:text-3xl md:text-[40px] font-bold leading-none">{title}</h2>

            <div
              className="prose prose-lg lg:prose-xl prose-p:font-medium prose-p:text-black/60 prose-p:leading-snug prose-strong:text-black/60 prose-a:text-current prose-a:underline hover:prose-a:opacity-60 prose-a:transition"
              dangerouslySetInnerHTML={{ __html: content }}>
            </div>

            {
              link ?
                <div className='mt-4'>
                  {
                    link.href ? <a href={link.href} target='__blank' className='group/link flex items-center justify-center w-fit px-5 py-2 h-12 md:h-14 bg-[#F8F8F8] rounded-2xl md:text-2xl font-bold'>
                      <span className=''>
                        {link.label}
                      </span>

                      <span className='block ml-4 transition group-hover/link:translate-x-2'>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_385_10056)">
                            <path d="M12 4L10.59 5.41L16.17 11L4 11L4 13L16.17 13L10.59 18.59L12 20L20 12L12 4Z" fill="black" />
                          </g>
                          <defs>
                            <clipPath id="clip0_385_10056">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                    </a> : null
                  }

                  {
                    link.to ? <Link to={link.to} target='__blank' className='group/link flex items-center justify-center w-fit px-5 py-2 h-14 bg-[#F8F8F8] rounded-2xl text-2xl font-bold'>
                      <span className=''>
                        {link.label}
                      </span>
                      <span className='block ml-4 transition group/link-hover:translate-x-2'>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_385_10056)">
                            <path d="M12 4L10.59 5.41L16.17 11L4 11L4 13L16.17 13L10.59 18.59L12 20L20 12L12 4Z" fill="black" />
                          </g>
                          <defs>
                            <clipPath id="clip0_385_10056">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                    </Link> : null
                  }

                </div>

                : null
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;