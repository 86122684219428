import { useCallback } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const useStaticImage = () => {
  const {
    allFile: { nodes },
  } = useStaticQuery(graphql`
    query getCloudLogosImages {
      allFile {
        nodes {
          relativePath
          childImageSharp {
            gatsbyImageData(
              # outputPixelDensities: 1.5
              formats: [AUTO, WEBP]
              placeholder: DOMINANT_COLOR
              quality: 100
              layout: CONSTRAINED
              webpOptions: {quality: 100}
              jpgOptions: {progressive: true, quality: 100}
              breakpoints: [750, 1920]
              # avifOptions: {quality: 100}
              pngOptions: {quality: 100}
            )
          }
        }
      }
    }
  `);

  return useCallback(
    (path) => nodes.find(({ relativePath }) => relativePath === path),
    [nodes]
  );
}

export default useStaticImage;
