import React from 'react';
import HeadSection from '@/components/common/Head';

import Header from '@/components/Header';
import Footer from '@/components/Footer';

import Card from '@/sections/media/Card';

const content = [
  {
    date: 'April 18, 2023',
    title: 'To Keep Web3 Safe, Security Needs to Be a Group Effort',
    description: `<p>SafeSoul public launch is coming and our team gets more and more visibility in the industry. We got featured in the article by&nbsp;<strong>nft&nbsp;now</strong> about Cyber Security, check it out.</p>`,
    image: `media/to-keep-web3-safe-security-needs-to-be-a-group-effort.jpeg`,
    link: {
      href: `https://nftnow.com/features/to-keep-web3-safe-security-needs-to-be-a-group-effort/`,
      //   to: `/live`,
      label: 'Link'
    }
  },

  {
    date: 'NY. April 14, 2023',
    title: 'SafeSoul team at NFT NYC 2023!',
    description: `<p>Our team has participated at the conference and ran a panel discussion about cyber security at the Community Stage. The topic of the session was monitoring in Web3 named “We know scams are sexy, but please use protection” where we’ve spoken about responsible security and community education.</p>
    <p>We got very inspired by irl networking with true builders and saw the necessity and uniqueness of our product on the market.</p>`,
    image: `media/safesoul-team-at-nft-nyc-2023.jpeg`,
    // link: {
    // href: `https://www.americanprogressaction.org/events/2020/11/10/488001/the-2020-election-what-happened-and-whats-next/`,
    //   to: `/live`,
    // label: 'To event'
    // }
  },

  {
    date: 'Moscow. August 18, 2022',
    title: 'Igor M\'s speeches at the web3 safety conference',
    description: `<p><a href="https://twitter.com/igor_3000A" target="__blank">Igor M</a> (Founder of Souls ecosystem and creator of <a href="https://opensea.io/collection/di-animals" target="__blank">Digital Animals</a> NFT collection) have spoken at the Web3 conference. Together with leading experts from the digital industry such as CEO of Binance CIS Vladimir Smerkis, Igor discussed the future of digital business and potential development scenarios. Within the presentation, Igor shared his expertise at Web3 and the specifics of NFT.</p>`,
    image: `media/igor-ms-speeches-at-the-web3-safety-conference.jpeg`,
  },

];

const Media = () => {
  return (
    <div>
      <Header />

      <main>
        <div className="pt-20 sm:pt-40 lg:pt-52 pb-20 lg:pb-40">
          <div className="fluid-container">
            <h1 className='text-[56px] sm:text-6xl font-bold'>Media</h1>
          </div>

          <section className="pt-2 sm:pt-10">
            <div className="fluid-container">
              <div className="space-y-6 sm:space-y-10 xl:space-y-20">

                {
                  content.map((item, index) => {
                    return (
                      <Card
                        key={index}
                        title={item.title}
                        content={item.description}
                        date={item.date}
                        image={item.image}
                        link={item.link}
                      />
                    )
                  })
                }

              </div>
            </div>
          </section>
        </div>
      </main>

      <Footer />

    </div>
  );
};

export default Media;

export const Head = () => <HeadSection title="Media" />;